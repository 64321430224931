import { useEffect, useState } from "react";
import getProject from "./getProject";

export default function useProject(projectId: string) {
  const [status, setStatus] = useState<"loading" | "loaded" | "error">(
    "loading",
  );
  const [project, setProject] = useState<typeof import("*.mdx")>();

  useEffect(() => {
    let promisedProject = getProject(projectId);
    if (promisedProject == null) {
      setStatus("error");
    } else {
      promisedProject.then((project) => {
        setProject(project);
        setStatus("loaded");
      });
    }
  }, [projectId]);

  return [project, status] as const;
}
