import '@emotion/cache';
import '@emotion/serialize';
import '@emotion/utils';
import createEmotion from '../create-instance/dist/emotion-css-create-instance.esm.js';

var _createEmotion = createEmotion({
  key: 'css'
}),
    flush = _createEmotion.flush,
    hydrate = _createEmotion.hydrate,
    cx = _createEmotion.cx,
    merge = _createEmotion.merge,
    getRegisteredStyles = _createEmotion.getRegisteredStyles,
    injectGlobal = _createEmotion.injectGlobal,
    keyframes = _createEmotion.keyframes,
    css = _createEmotion.css,
    sheet = _createEmotion.sheet,
    cache = _createEmotion.cache;

export { cache, css, cx, flush, getRegisteredStyles, hydrate, injectGlobal, keyframes, merge, sheet };
