let shouldScrollTop = false;

export default function useNeedToScrollTop() {
  return [
    function scrollTop() {
      if (shouldScrollTop) {
        shouldScrollTop = false;
        window.scrollTo(0, 0);
      }
    },
    function setShouldScrollTop() {
      shouldScrollTop = true;
    },
  ] as const;
}
