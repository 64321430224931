import { css } from "@emotion/css";
import { PxValue, RemValue } from "./DesignSystemLayout";

export const Img: React.FC<{
  src: string;
  title?: string;
  naturalSize: [number, number];
  radius?: string | "circular";
  width?: PxValue | RemValue;
  height?: PxValue | RemValue;
  draggable?: boolean;
}> = (props) => {
  const {
    radius,
    naturalSize,
    width: _width,
    height: _height,
    ...rest
  } = props;
  const [width, height] = props.naturalSize;
  const className = css`
    width: ${props.width ?? "auto"};
    height: ${props.height ?? "auto"};
    border-radius: ${radius === "circular" ? "50%" : radius};
  `;

  return <img {...{ className, width, height, ...rest }} />;
};
