import { css, cx } from "@emotion/css";
import { Link } from "react-router-dom";

export type PxValue = "0" | `${number}px`;
export type LowPxValue = "0" | `${1 | 2 | 3}px`;
export type RemValue = "0" | `${number}rem`;
export type VwValue = "0" | `${number}vw`;

export const FlexChild: React.FC<{
  basis?: RemValue;
  grow?: number;
  shrink?: number;
}> = (props) => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;

        flex-grow: ${props.grow || 0};
        flex-shrink: ${props.shrink || "unset"};
        flex-basis: ${props.basis};
      `}
    >
      {props.children}
    </div>
  );
};

export const Box: React.FC<{
  // Contents
  children: React.ReactElement | string;

  // Interactivity
  to?: string;
  onClick?: () => void;

  // Styling
  className?: Parameters<typeof cx>[0];
  colors?: [string, string] | [[string, string], [string, string]];
  center?: true;
  padding?: RemValue | `${RemValue} ${RemValue | VwValue | LowPxValue}`;
  maxWidth?: PxValue;
}> = (props) => {
  if (props.padding != null && props.maxWidth != null) {
    let { maxWidth, children, ...rest } = props;

    return (
      <Box {...rest}>
        <Box
          {...{ maxWidth, children }}
          className={css`
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          `}
        />
      </Box>
    );
  }

  let colorsStyle;
  if (props.colors == null) {
    colorsStyle = css``;
  } else if (typeof props.colors[0] === "string") {
    colorsStyle = css`
      background-color: ${props.colors[0]};
      color: ${props.colors[1]};
    `;
  } else {
    colorsStyle = css`
      background-color: ${props.colors[0][0]};
      color: ${props.colors[0][1]};

      @media (prefers-color-scheme: dark) {
        background-color: ${props.colors[1][0]};
        color: ${props.colors[1][1]};
      }
    `;
  }

  const elementProps = {
    className: cx(
      css`
        display: block;
        box-sizing: border-box;
        padding: ${props.padding};
        align-self: ${props.center ? "center" : undefined};
        max-width: ${props.maxWidth};
        width: ${props.maxWidth ? "100%" : undefined};
        margin: ${props.maxWidth ? "0 auto" : undefined};

        ${colorsStyle}
        cursor: ${props.to != null || props.onClick != null
          ? "pointer"
          : undefined};
      `,
      props.className,
    ),
    children: props.children,
    onClick: props.onClick,
  };

  if (props.to == null) {
    return <div {...elementProps} />;
  } else {
    return <Link to={props.to} {...elementProps} />;
  }
};

export const Col: React.FC<{
  className?: Parameters<typeof cx>[0];
  gap?: RemValue;
  x?: "center" | "start" | "end";
  y?: "center" | "start" | "end" | "stretch";
}> = (props) => {
  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: column;
          gap: ${props.gap};
          justify-content: ${props.y};
          align-items: ${props.x};
        `,
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};

export const Row: React.FC<{
  className?: Parameters<typeof cx>[0];
  reverse?: boolean;
  gap?: RemValue | `${RemValue} ${RemValue}`;
  evenSplit?: number;
  itemMinWidth?: PxValue;
  itemBorder?: string;
  colIfAtMost?: PxValue;
  x?: "center" | "start" | "end";
  y?: "center" | "start" | "end";
}> = (props) => {
  let flexBasis;
  if (props.evenSplit != null && props.itemMinWidth != null) {
    flexBasis = `max(${props.itemMinWidth}, ${100 / props.evenSplit}%)`;
  } else if (props.evenSplit != null) {
    flexBasis = `${100 / props.evenSplit}%`;
  } else if (props.itemMinWidth != null) {
    flexBasis = props.itemMinWidth;
  }

  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: ${props.reverse ? "row-reverse" : "row"};
          flex-wrap: ${props.itemMinWidth != null ? "wrap" : undefined};
          justify-content: ${props.x};
          align-items: ${props.y};
          gap: ${props.gap};
          border: ${props.itemBorder};
          border-right: 0;
          border-bottom: 0;

          @media (max-width: ${props.colIfAtMost}) {
            flex-wrap: wrap;
          }

          & > * {
            box-sizing: border-box;
            flex-basis: ${flexBasis};
            flex-shrink: ${props.itemMinWidth != null ? 0 : undefined};
            flex-grow: ${props.evenSplit != null ? 1 : undefined};
            border: ${props.itemBorder};
            border-left: 0;
            border-top: 0;

            @media (max-width: ${props.colIfAtMost}) {
              flex-basis: 100%;
            }
          }
        `,
        props.className,
      )}
    >
      {props.children}
      {props.evenSplit != null &&
        props.itemMinWidth != null &&
        new Array(props.evenSplit - 1).fill(null).map((_, i) => (
          <span
            className={css`
              * > & {
                border: none;
              }
            `}
            key={i}
          />
        ))}
    </div>
  );
};

export const Hr: React.FC = () => {
  return (
    <hr
      className={css`
        border: none;
        border-bottom: 1px solid currentColor;
        margin: unset;
        width: 100%;
      `}
    />
  );
};
