import { css } from "@emotion/css";
import { MDXProvider } from "@mdx-js/react";
import React, { useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Col,
  FlexChild,
  Hr,
  Row,
} from "../DesignSystem/DesignSystemLayout";
import useShouldScrollTop from "../Pages/useShouldScrollTop";
import useProject from "./useProject";

const DocLink: React.FC<{
  href: string;
  children: React.ReactNode;
}> = (props) => {
  return props.href?.startsWith("/") ? (
    <Link to={props.href}>{props.children}</Link>
  ) : (
    <a target="_blank" href={props.href}>
      {props.children}
    </a>
  );
};

function PageloadEffect() {
  const [scrollTop] = useShouldScrollTop();

  return <noscript ref={scrollTop} />;
}

const ProjectPage: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const params = useParams<{ projectId: string }>();
  const [Doc, DocStatus] = useProject(params.projectId);
  const lastLoadedDocIdRef = useRef<string | null>(null);

  if (DocStatus === "loaded") {
    lastLoadedDocIdRef.current = params.projectId;
  }

  useEffect(() => {
    if (DocStatus === "error") {
      navigate("/home", { replace: true });
    }
  }, [Doc]);

  return (
    <Col>
      <Row
        gap="0.5rem"
        className={css`
          user-select: none;
        `}
      >
        <Box padding="1rem" onClick={() => history.back()}>
          <small>&larr; Back</small>
        </Box>
        <Box padding="1rem" to="/home">
          <strong>Sean Zhu</strong>
        </Box>
        <FlexChild grow={1} />
        <Box padding="1rem" to="/link/33b05ed">
          <small>Share Page URL</small>
        </Box>
      </Row>
      <Hr />
      {DocStatus === "loaded" &&
        (Doc.meta.theme === "blank" ? (
          <Box>
            <>
              <Doc.default />
              <PageloadEffect />
            </>
          </Box>
        ) : (
          <Box padding="2rem">
            <Col
              gap="1.3rem"
              className={css`
                & > * {
                  box-sizing: border-box;
                  width: 800px;
                  max-width: 100%;
                  margin: 0 auto;
                }

                & > header {
                  font: 700 2rem var(--font-serif);
                }

                & > header + p {
                  font: 400 1.3rem var(--font-serif);
                }

                & > p {
                  font: 400 1rem var(--font-serif);
                }

                & a {
                  text-decoration: underline;
                }

                & > ul,
                & > ol {
                  display: flex;
                  flex-direction: column;
                  gap: 0.5rem;
                  padding: 0;
                }
              `}
            >
              <header>{Doc.meta.title}</header>
              <MDXProvider components={{ h1: "header", a: DocLink } as any}>
                <Doc.default />
              </MDXProvider>
              <PageloadEffect />
            </Col>
          </Box>
        ))}
    </Col>
  );
};

export default ProjectPage;
