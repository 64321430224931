import "@fontsource/libre-baskerville";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Link,
  MemoryRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import HomePage from "./Home/HomePage";
import useNeedToScrollTop from "./Pages/useShouldScrollTop";
import ProjectPage from "./Project/ProjectPage";

function useSyncLocation() {
  const routerLocation = useLocation();
  const navigate = useNavigate();
  const [, setShouldScrollTop] = useNeedToScrollTop();

  useEffect(() => {
    // When the page is first loaded:
    if (routerLocation.pathname == "/") {
      if (window.location.pathname !== "/") {
        navigate(window.location.pathname, { replace: true });
      } else if (history.state?.path) {
        navigate(history.state.path, { replace: true });
      } else {
        navigate("/home", { replace: true });
      }
    }

    // On subsequent navigations:
    else {
      if (history.state == null) {
        history.replaceState({ path: routerLocation.pathname }, "", "/");
      } else if (history.state.path === routerLocation.pathname) {
        // Do nothing
      } else {
        history.pushState(
          { path: routerLocation.pathname, lastPath: history.state.path },
          "",
          "/",
        );
        setShouldScrollTop();
      }
    }
  }, [routerLocation.pathname]);

  useEffect(() => {
    function popStateListener(event: PopStateEvent) {
      if (event.state?.path) {
        navigate(event.state.path);
      }
    }

    window.addEventListener("popstate", popStateListener);
    return () => window.removeEventListener("popstate", popStateListener);
  }, []);
}

function Hook(): React.ReactElement {
  useSyncLocation();
  return null;
}

function App() {
  return (
    <MemoryRouter>
      <Hook />
      <Routes>
        <Route path="/link/:projectId" element={<ProjectPage />} />
        <Route path="/" element={<Link to="/home">Home</Link>} />
        <Route path="/*" element={<HomePage />} />
      </Routes>
    </MemoryRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("app"));
