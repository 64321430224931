export default function getProject(projectId: string) {
  switch (projectId) {
    // autogenerated-content

    case "33b05ed":
      return import("../Projects/about-urls-33b05ed.mdx");

    case "6179e3a":
      return import("../Projects/design-nom-6179e3a.mdx");

    case "54d46b0":
      return import("../Projects/design-nom-figma-test-54d46b0.mdx");

    case "b641b1a":
      return import("../Projects/design-portfolio-b641b1a.mdx");

    case "deaaf3c":
      return import("../Projects/interest-democracy-deaaf3c.mdx");

    case "9dccd30":
      return import("../Projects/resume-9dccd30.mdx");

    // autogenerated-content
  }
}
