import { css } from "@emotion/css";
import { Box, Col } from "../DesignSystem/DesignSystemLayout";
import useProject from "./useProject";

const ProjectTile: React.FC<{
  id: string;
  title?: string;
  summary?: string;
  border?: true;
}> = (props) => {
  const [project, projectStatus] = useProject(props.id);

  if (projectStatus !== "loaded") return null;

  return (
    <Box
      padding="1rem"
      to={`/link/${props.id}`}
      className={css`
        border: ${props.border ? "1px solid currentColor" : undefined};
      `}
    >
      <Col
        gap="0.5rem"
        className={css`
          & > header {
            font: 700 1.3rem var(--font-serif);
          }

          & > p {
            font: 400 1rem var(--font-serif);
          }
        `}
      >
        <header>{props.title ?? project.meta.title}</header>
        <p>{props.summary ?? project.meta.summary}</p>
      </Col>
    </Box>
  );
};

export default ProjectTile;
