import { css } from "@emotion/css";
import { Box, Col, FlexChild, Row } from "../DesignSystem/DesignSystemLayout";
import { Img } from "../DesignSystem/DesignSystemMedia";
import ProjectGalleryHeader from "../Project/ProjectGalleryHeader";
import ProjectTile from "../Project/ProjectTile";
import { useGreeting } from "./HomeGreeting";

let Stretch = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const HomePageComponent: React.FC<{}> = (props) => {
  const greeting = useGreeting();

  return (
    <Col
      className={css`
        min-height: 100vh;
        /*
         * On Safari 16.0 (18614.1.19.1.5), 1svh > 1vh, which makes no sense,
         * because (1) 1svh is supposed to be the smallest possible value of vh,
         * and because (2) in desktop where the browser chrome is fixed, it
         * should always be that 1vh = 1svh = 1lvh = 1dvh.
         *
         * Anway, not sure why it's happening, but getting the min fixes it.
         */
        min-height: min(100vh, 100svh);
        user-select: none;
      `}
      y="stretch"
    >
      <FlexChild grow={1}>
        <Box
          maxWidth="700px"
          padding="2rem 5vw"
          colors={[
            ["darkblue", "white"],
            ["#000068", "white"],
          ]}
          className={Stretch}
        >
          <Col y="end" className={Stretch}>
            <Row
              gap="2rem 1rem"
              itemMinWidth="200px"
              x="center"
              y="center"
              reverse
            >
              <FlexChild grow={0}>
                <Img
                  draggable={false}
                  radius="circular"
                  title={greeting}
                  src="https://www.ikea.com/us/en/images/products/fejka-artificial-potted-plant-with-pot-indoor-outdoor-succulent__0614211_pe686835_s5.jpg?f=s"
                  naturalSize={[600, 600]}
                  width="200px"
                />
              </FlexChild>
              <FlexChild grow={1} shrink={1}>
                <Col
                  gap="0.5rem"
                  className={css`
                    & > header {
                      font: 700 2rem var(--font-serif);
                    }

                    & > p {
                      font: 400 1rem var(--font-serif);
                    }
                  `}
                >
                  <header>Hi, I’m Sean.</header>
                  <p>I’m a software engineer and aspiring designer.</p>
                  <p>
                    I want to create tools that enable people to do more of what
                    they want to do.
                  </p>
                  <p>
                    I want what I make to be useful for the general public,
                    especfially for people historically underserved by tech.
                  </p>
                </Col>
              </FlexChild>
            </Row>
          </Col>
        </Box>
      </FlexChild>
      <Box
        maxWidth="700px"
        padding="2rem 5vw"
        colors={[
          ["white", "black"],
          ["#2a2525", "white"],
        ]}
        className={css`
          flex-grow: 1;
        `}
      >
        <Col
          gap="2rem"
          className={css`
            & > header {
              font: 700 2rem var(--font-serif);
            }

            & > p {
              font: 400 1rem var(--font-serif);
            }
          `}
        >
          <p>
            I’ll use this website to post things I’m working on or things I
            think about. It currently looks empty — no content is browseable
            from the home page right now.
          </p>
          <p>
            For inquiries, please email me at{" "}
            <a href="mailto:hello@szhu.me">hello@szhu.me</a>.
          </p>
        </Col>
      </Box>
      {/* <FlexChild basis="0.5rem" /> */}
      {false && (
        <>
          <Box maxWidth="1200px" padding="2rem 5vw">
            <Col gap="1rem">
              <ProjectGalleryHeader>
                Some random projects I’ve worked on:
              </ProjectGalleryHeader>
            </Col>
          </Box>
          <Box maxWidth="1400px" padding="0 2px">
            <Row
              itemBorder="1px solid currentColor"
              evenSplit={3}
              colIfAtMost="600px"
            >
              <ProjectTile id="deaaf3c" />
              <ProjectTile id="9dccd30" />
              <ProjectTile id="b641b1a" />
            </Row>
          </Box>
        </>
      )}
    </Col>
  );
};

export default HomePageComponent;
